<template>
  <p-dialog title="Fakturagrunnlag fra SmartRetur" classes="w-3/5" @close="$emit('close')">

    <purchase-dialog-invoice :order-id="purchase.id" ></purchase-dialog-invoice>

    <p-dialog-actions>
      <div class="flex justify-between w-full">
        <div class="flex gap-2">          
          <email-invoice-button :invoiceIds="purchase.invoiceIds"></email-invoice-button>
        </div>
        <p-button @click="$emit('close')">{{ $tk('Common.Actions.Close', true) }}</p-button>
      </div>
    </p-dialog-actions>
  </p-dialog>
</template>

<script>

import PurchaseDialogInvoice from './PurchaseDialog_Invoice.vue'
import EmailInvoiceButton from "../components/EmailInvoiceButton.vue"
import { map, first } from 'lodash'

export default {
  name: 'PurchaseDialog',
  components: {
    PurchaseDialogInvoice,
    EmailInvoiceButton
  },
  data() {
    return {
      tab: ''
    }
  },

  props: {
    purchase: {
      type: Object,
      default: () => { }
    }
  },

  computed: {
    invoices: function () {
      if (this.purchase.targetInvoiceIds.length > 0) {
        return map(this.purchase.targetInvoiceIds, r => {
          return {
            invoiceId: r,
            orderId: this.purchase.id
          }
        })
      } else {
        return [{ invoiceId: null, orderId: this.purchase.id }]
      }
    },
  },

  methods: {
    downloadInvoice() {

    }
  },

  mounted() {
    this.tab = first(this.invoices);
  },

}
</script>