<template>
  <div class="flex gap-2">
    <p-button icon="envelope" color="secondary" v-show="!emailVisible" @click="onShowSendEmail">{{ $tk("Reports.SendCopyOfInvoice") }}</p-button>
    <form @submit.prevent="onSubmitEmail" class="flex gap-2 -m-1 p-1 bg-green-100 rounded" v-show="emailVisible">
      <p-input v-model="invoiceEmail" type="email" :focus="emailVisible" class="w-48" placeholder="E-post" iconInsideLeft="envelope" />
      <p-button type="submit" icon="envelope" color="primary" :disabled="invoiceEmail === ''">{{ $tk("Common.Actions.Send") }}</p-button>
      <p-button icon="" color="danger" @click="emailVisible = false">{{ $tk("Common.Actions.Cancel") }}</p-button>
    </form>
  </div>
</template>

<script>
import http from "@/http"

export default {
  props: {
    invoiceId: {
      type: String,      
    },
    invoiceIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      emailVisible: false,
      invoiceEmail: ""
    }
  },
  methods: {
    onShowSendEmail() {
      this.emailVisible = true;
    },
    async onSubmitEmail() {
      
      try {

        let promises = [];

        if (this.invoiceId) {
          promises.push(http.get("invoiceDocument", {
            params: {
              id: this.invoiceId,
              sendToEmail: this.invoiceEmail
            }
          }));
        }

        if (this.invoiceIds.length > 0) {
          for (let i = 0; i < this.invoiceIds.length; i++) {
            promises.push(http.get("invoiceDocument", {
              params: {
                id: this.invoiceIds[i],
                sendToEmail: this.invoiceEmail
              }
            }));
          }
        }

        await Promise.all(promises);

        this.emailVisible = false;
        this.invoiceEmail = "";
      } catch (error) {
        console.error(error);
      }
    }
  }
}

</script>