<template>
  <tbody v-if="order && transactions.length > 0" @click="expanded = !expanded">
    <template>
      <tr :class="expanded ? 'orange font-semibold' : 'hover:bg-gray-200'">
        <td>{{order.id}}</td>
        <td>{{order.dateSend | date }}</td>
        <td>{{order.dateArrived | date }}</td>
        <td>{{order.transactionTypeId}}</td>
        <td>{{getStatusText(order.transactionStatus, this)}}</td>
        <td>{{order.locationNameFrom}}</td>
        <td>{{order.locationNameTo}}</td>
        <td>{{map(order.transactions, r => r.productName).join(', ')}}</td>
        <td></td>
        <td class="text-right">{{calculateOrderTransactions(order)}}
        <td class="text-right">{{calculateOrderSorted(order)}}</td>
        <td class="text-right">{{calculateOrderRepaired(order)}}</td>
        <td>{{order.currencyIdFrom}}</td>
        <td class="text-right">{{formatNumber(order.feePrice)}}</td>
        <td class="text-right">{{formatNumber(order.feeTransport)}}</td>
        <td class="text-right">{{formatNumber(order.amount)}}</td>
        <td>{{order.sendersRef}}</td>
        <td>{{order.receiversRef}}</td>
        <td :rowspan="expanded ? calculateRowspan(order) + 1 : 1" class="text-center">
          <font-awesome-icon v-if="!expanded" :icon="['far', 'chevron-down' ]" />
          <font-awesome-icon v-else :icon="['far', 'chevron-up' ]" />
        </td>
      </tr>
    </template>
    <template v-if="expanded">
      <template v-for="(transaction, index) in transactions">
        
        <!-- Transaction -->
        <tr :key="index" class="orange font-semibold">
          <td><span class="ml-2">Produkt</span></td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)"><!--{{order.dateSend | date}}--></td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)"><!-- {{order.dateArrived | date}} --></td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)"><!-- {{order.transactionTypeId}} --></td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)"><!-- {{getStatusText(order.transactionStatus)}} --></td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)"><!-- {{order.locationNameFrom}} --></td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)"><!-- {{order.locationNameTo}} --></td>
          <td>
            <div class="flex items-center">
              <!-- <img :src="assetsBaseUrl + transaction.productImage" class="w-10 h-6 block" /> -->
              <div class="font-semibold">{{transaction.productName}}, {{transaction.qualityName}}</div>
            </div>
          </td>
          <td>{{order.transactionTypeId != 'HE' ? productType(transaction) : 'Sort'}}</td>
          <td class="text-right" >{{formatQuantity(transaction.quantity)}}</td>
          <td class="text-right">{{calculateTransactionSorted(transaction)}}</td>
          <td class="text-right">{{calculateTransactionRepaired(transaction)}}</td>
          <td>{{transaction.currencyIdFrom}}</td>
          <td class="text-right">{{formatNumber(transaction.feePrice)}}</td>
          <td v-if="index == 0" class="text-right" :rowspan="calculateRowspan(order)"><!-- {{transaction.feeTransport}} --></td>
          <td v-if="index == 0" class="text-right" :rowspan="calculateRowspan(order)"><!-- {{transaction.feePrice}} --></td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)">{{transaction.sendersRef}}</td>
          <td v-if="index == 0" :rowspan="calculateRowspan(order)">{{transaction.receiversRef}}</td>
        </tr>

        <template v-for="(sort, i) in sorts(transaction)" >
          <tr :key="`${index}_${i}`" class="orange italic">
            <td><span class="ml-4">Sortert</span></td>
            <td>
              <div class="flex items-center">
                <!-- <img :src="assetsBaseUrl + sort.productImage" class="w-10 h-6 block" /> -->
                <div class="font-medium italic ml-2"> -> {{sort.productName}}, {{sort.qualityName}}</div>
              </div>
            </td>
            <td>{{productType(sort)}}</td>
            <td v-if="i == 0" :rowspan="calculateRowspanSort(transaction)" ></td>
            <td class="text-right">{{formatQuantity(sort.quantity)}}</td>
            <td class="text-right">{{calculateSortRepaired(sort)}}</td>
            <td>{{sort.currencyId}}</td>
            <td class="text-right">{{formatNumber(sort.amount)}}</td>
          </tr>
            <tr v-for="(qtyChange, j) in sort.qualityChanges" :key="`${index}_${i}_${j}`" class="orange">
              <td><span class="ml-6">Reparert</span></td>
              <td>
                <div class="flex items-center">
                  <!-- <img :src="assetsBaseUrl + sort.productImage" class="w-10 h-6 block" /> -->
                  <div class="font-medium ml-4"> -> {{qtyChange.productName}}, {{qtyChange.newQualityName}}</div>
                </div>
              </td>
              <td>{{productType(qtyChange)}}</td>
              <td v-if="j === 0" :rowspan="calculateRowspanRep(sort)"></td>
              <td class="text-right">{{formatQuantity(qtyChange.quantity)}}</td>
              <td v-if="j === 0" :rowspan="calculateRowspanRep(sort)"></td>
              <td v-if="j === 0" :rowspan="calculateRowspanRep(sort)"></td>
            </tr>
        </template>
      </template> 
    </template>
  </tbody>
</template>

<script>
  import { getStatusText } from "@/helpers"
  import { mapGetters } from 'vuex'
  import { concat, map, filter, flatMap, forEach, sumBy, sum } from "lodash"

  export default {
    name: "Transaction",
    data() {
      return {
        expanded: false,
      }
    },
    computed: {
      ...mapGetters(['location']),
      assetsBaseUrl () {
        return this.$appConfig.assetsBaseUrl
      },
      showRepair () {
        return this.location.isForcedRep === 1
      },
      transactions () {
        if (this.productFilter !== '-1') {
          return filter (this.order.transactions, trans => this.productFilter === trans.productId)
        } else {
          return this.order.transactions
        }
      }
    },
    methods: {
      getStatusText,      
      map,
      sum,
      sumBy,

      calculateRowspan(order, transaction) {
        if (transaction == null) {
          const sorts = flatMap(this.transactions, trans => this.sorts(trans))
          const qtys = flatMap(sorts, sort => sort.qualityChanges)
          return this.transactions.length + sorts.length + qtys.length 
        } else if (order == null) {
          return this.sorts(transaction).length
        } else {
          return this.transactions.length + this.sorts(transaction).length
        }
      },
      calculateRowspanSort(transaction) {
        const qtys = filter(flatMap(this.sorts(transaction), sort => sort.qualityChanges), r => r != null)
        return this.sorts(transaction).length + qtys.length
      },
      calculateRowspanRep(sort) {
        return sort.qualityChanges != null ? sort.qualityChanges.length : 1
      },
      calculateOrderTransactions() {
        return this.formatQuantity(sumBy(this.transactions, r => r.quantity))
      },
      calculateOrderSorted() {
        let sorted = []
        forEach(this.transactions, trans => {
          sorted = concat(sorted, this.sorts(trans))
        })
        return this.formatQuantity(sumBy(sorted, 'quantity'))
      },
      calculateTransactionSorted(transaction) {
        return this.formatQuantity(sumBy(this.sorts(transaction), 'quantity'))
      },
      calculateOrderRepaired() {
        let repaired = []
        forEach(this.transactions, trans => {
          forEach(this.sorts(trans), sort => {            
            repaired = concat(repaired, sort.qualityChanges)
          })
        })
        return this.formatQuantity(sumBy(repaired, 'quantity'))
      },
      calculateTransactionRepaired(transaction) {
        let repaired = []
        forEach(this.sorts(transaction), sort => {
          repaired = concat(repaired, sort.qualityChanges)
        })
        return this.formatQuantity(sumBy(repaired, 'quantity'))
      },
      calculateSortRepaired(sort) {
        return this.formatQuantity(sumBy(sort.qualityChanges, 'quantity'))
      },
      formatQuantity(quantity) {
        if (this.order.directionLocation === 'Out') {
          return (quantity != null && quantity > 0 ? ' - ' + quantity : '')
        } else {
          return (quantity != null && quantity > 0 ? quantity : '')
        }
      },
      formatNumber (number) {
        if (number != null && number > 0) {
          return new Intl.NumberFormat(this.$i18n.locale, { minimumFractionDigits: 0 }).format(number)
        } else {
          return ''
        }
      },
      sorts (transaction) {
        if (this.productFilter !== '-1') {
          return filter (transaction.sorts, t => this.productFilter === t.productId)
        }
        else {
          return transaction.sorts
        }
      },
      productType(element) {
        const types = []
        if (element.isCommission) {
          types.push('Kom')
        } 
        if (element.isPooling) {
          types.push('Pooling')
        } 
        if (types.length === 0) {
          if (this.order.transactionTypeId == 'HE') {
            types.push('Salg')
          } else if (this.order.transactionTypeId == 'TO') {
            types.push('Kjøp')
          }
        }
        return types.join(', ')
      }
    },
    props: {
      order: {
        type: Object,
        default: () => undefined
      },
      productFilter: {
        type: String,
        default: '-1' // 0 No filter, 1 Kjøp / Salg, 2 Kommisjon, 3 Pooling
      }
    }
  }
</script>

<style scoped>

  tr.orange {
    @apply bg-orange-200
  }

  td {
    @apply p-2;
  }

</style>